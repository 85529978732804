import React, { Component } from "react";
import json from "./questionsResponse.json";
import _ from "lodash";
import axios from "axios";
import { openNotification } from "./util";
import { Row, Col, Button, Tree, Input, Select } from "antd";

const { TreeNode } = Tree;
const Option = Select.Option;
export default class Sink extends Component {
  constructor() {
    super();
    this.state = {
      categoryId: "4",
      json: [],
      parent: [],
      expandedKeys: ["0"],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      currentQuestion: {
        id: null,
        name: "",
        sub_name: "",
        summary_name: null,
        hint: null,
        parent_id: null,
        is_primary: null,
        parent_ans_state: null,
        question_type: "text",
        has_child: 0,
        restrict_value_start: null,
        restrict_value_end: null,
        possible_answer: [],
        tags: {
          clinic_question_id: null,
          tag_id: null,
          tag_name: "",
          position: null,
          associate_id: null
        },
        answers: [],
        children: []
      }
    };
  }
  onExpand = expandedKeys => {
    console.log("onExpand", expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  onCheck = checkedKeys => {
    console.log("onCheck", checkedKeys);
    this.setState({ checkedKeys });
  };

  onSelect = (selectedKeys, info) => {
    console.log("onSelect", info);
    this.setState({ selectedKeys });
  };

  restructure = jsonArray => {
    let structured = jsonArray.map((x, y) => {
      return {
        ...x,
        title: x.name,
        key: x.id,
        children: []
      };
    });

    this.setState({ json: structured }, () => {
      this.findParent();
    });
  };
  findParent = () => {
    let ques = _.uniqBy(this.state.json, "id");
    let gen = _.filter(ques, x => x.parent_id == 0).map((x, y) => {
      return {
        ...x,
        title: x.name,
        key: x.id,
        children: []
      };
    });
    // let ques_arr = []
    // let gen = ques.map((x, y) => {
    //   if (x.has_child == 1) {
    //     console.log(x, _.find(ques, z => z.parent_id == x.id));
    //   }
    //  else {
    //   console.log('no');
    // }
    // })

    console.log(gen);
    this.setState({
      parent: gen
    });
  };
  findChild = (id, target) => {
    console.log(id, target);
    let ques = json.question_list.questions;
    let gen = _.filter(this.state.json, x => x.parent_id == id);
    console.log("gen", _.filter(this.state.json, x => x.parent_id == id));
    return gen;
  };
  getDetails = (id, target) => {
    console.log(id, target);
    this.setState({
      currentQuestion: id
    });
  };

  onLoadData = treeNode =>
    new Promise(resolve => {
      if (treeNode.props.children) {
        console.log(treeNode.props);
        setTimeout(() => {
          // treeNode.props.dataRef.children = [
          //   { title: "Child Node", key: `${treeNode.props.eventKey}-0` },
          //   { title: "Child Node", key: `${treeNode.props.eventKey}-1` }
          // ];
          treeNode.props.dataRef.children = this.findChild(
            treeNode.props.dataRef.id
          );
          this.setState({
            parent: [...this.state.parent]
          });
          resolve();
        }, 1000);
        resolve();
        return;
      }
    });

  renderTreeNodes = data => {
    if (data) {
      return data.map(item => {
        return (
          <TreeNode
            title={
              <div data-child={item.has_child == 0 ? false : true}>
                <div>
                  {item.title}
                  <Button onClick={this.getDetails.bind(this, item)}>
                    Get
                  </Button>
                </div>
              </div>
            }
            key={item.key}
            dataRef={item}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      });
    }
  };
  onQuestionOptionNameChange = (index, e) => {
    let copyState = { ...this.state };
    copyState.currentQuestion.possible_answer[index].answer_name = e.target.value;
    this.setState(copyState);
  };
  onQuestionOptionSnameChange = (index, e) => {
    let copyState = { ...this.state };
    copyState.currentQuestion.possible_answer[index].summary = e.target.value;
    this.setState(copyState);
  };
  removeOptionsHandler = (index, e) => {
    console.log(index, e);
    let copyState = { ...this.state };
    let questOptions = copyState.currentQuestion.possible_answer;
    let removeTheOption = questOptions.splice(index, 1);
    this.setState(copyState);
  };
  deleteQuestionHandler = (index, context) => {
    let quesitonItem = this.state.filtered_questions[index];

    const config = {
      headers: { "content-type": "multipart/form-data" }
    };
    let postURL;
    if (
      this.props.match.params.categoryId == 1 ||
      this.props.match.params.categoryId == 2 ||
      this.props.match.params.categoryId == 8
    ) {
      postURL = `${process.env.REACT_APP_API_ROOT}/question?doctor_id=${
        this.props.match.params.doctorId
      }&clinic_id=${this.props.match.params.clinicId}&question_id=${
        quesitonItem.id
      }`;
    } else {
      postURL = `${process.env.REACT_APP_API_ROOT}/question?doctor_id=${
        this.props.match.params.doctorId
      }&clinic_id=${this.props.match.params.clinicId}&question_id=${
        quesitonItem.id
      }&tag_id=${quesitonItem.tags.tag_id}`;
    }
    axios
      .delete(postURL, config)
      .then(res => {
        if (res.data.status == true) {
          openNotification("success", "Deleted Successfully");
          this.setState(
            {
              loading: false
            },
            () => {
              this.fetchQuestions();
            }
          );
        } else {
          openNotification("error", `${res.data.message}`);
          this.setState({
            loading: false
          });
        }
        console.log("res", res);
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  };
  addOptionsHandler = (index, e) => {
    let emptyOption = {
      answer_name: "",
      summary: "",
      question_id: index
    };
    let copyState = { ...this.state };
    let questOptions = copyState.currentQuestion.possible_answer;
    questOptions.push(emptyOption);
    console.log(questOptions);
    this.setState(copyState);
  };
  getGlobalQuestions = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_ROOT}/admin/getQuestions?category_id=${
          this.state.categoryId
        }&clinic_id=${this.props.match.params.clinicId}`
      )
      .then(res => {
        // console.log(res);
        // console.log(res.data.question_list.questions);
        // if (res.data.list !== null) {
        //   this.restructure(res.data.list);
        // }
        if (res.data.question_list.question !== null) {
          this.restructure(res.data.question_list.questions);
        }
      });
  };
  handleTypeChange = value => {
    this.setState(
      {
        categoryId: value
      },
      () => {
        this.getGlobalQuestions();
      }
    );
  };
  componentDidMount() {
    if (!localStorage.token) {
      window.location.href = "/auth";
    }
    this.getGlobalQuestions();
    // this.restructure(json.question_list.questions);
  }
  render() {
    return (
      <React.Fragment>
        <Row>
          <Select
            defaultValue="4"
            style={{ width: 120 }}
            onChange={this.handleTypeChange}
          >
            <Option value="2">Physical Examination</Option>
            <Option value="3">Complaints</Option>
            <Option value="4">Medical History</Option>
            <Option value="5">Personal History</Option>
            <Option value="6">Family History</Option>
            <Option value="7">Occupational History</Option>
            <Option value="18">Eye Examination</Option>
            <Option value="20">New Born Examination</Option>
          </Select>
        </Row>
        {/* <Button onClick={this.findParent}>generate</Button> */}
        <Row>
          <Col span={6}>
            <Tree onExpand={this.onExpand} loadData={this.onLoadData}>
              {this.renderTreeNodes(this.state.parent)}
            </Tree>
          </Col>
          <Col span={12}>
            {this.state.currentQuestion.possible_answer.length > 0 && (
              <React.Fragment>
                <Row>
                  <input defaultValue={this.state.currentQuestion.title} />
                </Row>

                <Row>
                  {this.state.currentQuestion.possible_answer.map(
                    (option, i) => {
                      return (
                        <React.Fragment>
                          <Input
                            id={0}
                            name="answer_name"
                            defaultValue={option.answer_name}
                            placeholder="Name"
                            onChange={this.onQuestionOptionNameChange.bind(
                              this,
                              i
                            )}
                            style={{ width: "35%", marginBottom: "1rem" }}
                          />
                          <Input
                            id={0}
                            name="summary"
                            defaultValue={option.summary}
                            placeholder="Summary Name"
                            onChange={this.onQuestionOptionSnameChange.bind(
                              this,
                              i
                            )}
                            style={{
                              width: "35%",
                              marginBottom: "1rem",
                              marginLeft: "0.5rem"
                            }}
                          />
                          {this.state.currentQuestion.possible_answer.length >
                            1 ? (
                              <Button
                                type="danger"
                                id={0}
                                onClick={this.removeOptionsHandler.bind(this, i)}
                                style={{
                                  marginLeft: "1rem",
                                  marginTop: "1rem"
                                }}
                              >
                                -
                              </Button>
                            ) : null}
                        </React.Fragment>
                      );
                    }
                  )}
                  <Button
                    id={0}
                    type="primary"
                    onClick={this.addOptionsHandler.bind(this, 0)}
                    style={{ marginLeft: "1rem" }}
                  >
                    +
                  </Button>
                </Row>
              </React.Fragment>
            )}
            <Row>
              <Button onClick={() => console.log(this.state.currentQuestion)}>Link</Button>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
