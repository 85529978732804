import React, { Component, Fragment } from "react";
import { List, Avatar, Row, Col, Button, Menu, Icon, Dropdown } from "antd";
import { Link } from "react-router-dom";
import logo from "./logo.png";
const SubMenu = Menu.SubMenu;

export default class Header extends Component {
  render() {
    if (window.location.pathname == "/auth") {
      return (
        <Menu mode="horizontal">
          <Menu.Item key="logo">
            <Link to="/">
              <img src={logo} width="50px" />
            </Link>
          </Menu.Item>
        </Menu>
      );
    } else {
      return (
        <Menu mode="horizontal">
          <Menu.Item key="logo">
            <Link to="/">
              <img src={logo} width="50px" />
            </Link>
          </Menu.Item>
          <SubMenu
            key="medicines"
            title={
              <span>
                <Icon type="medicine-box" />
                <span>Medicines</span>
              </span>
            }
          >
            <Menu.Item key="view">
              <Link to="/medicine/view">Search</Link>
            </Menu.Item>
            <Menu.Item key="requests">
              <Link to="/medicine/requests">Requested</Link>
            </Menu.Item>
            <Menu.Item key="add">
              <Link to="/medicine/add">Add</Link>
            </Menu.Item>
            <Menu.Item key="edit">
              <Link to="/medicine/edit">Edit</Link>
            </Menu.Item>
            <Menu.Item key="match">
              <Link to="/match">Match</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="clones"
            title={
              <span>
                <Icon type="diff" />
                <span>Clones</span>
              </span>
            }
          >
            <Menu.Item key="cquestions">
              <Link to="/clone/questions">Clone Questions</Link>
            </Menu.Item>
            <Menu.Item key="cdiagnosis">
              <Link to="/clone/diagnosis">Clone Diagnosis</Link>
            </Menu.Item>
            <Menu.Item key="cprescriptions">
              <Link to="/clone/prescriptions">Clone Prescriptions</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="globals"
            title={
              <span>
                <Icon type="global" />
                <span>Add Globals</span>
              </span>
            }
          >
            <Menu.Item key="globalVitals">
              <Link to="/add/vital/globalList">Vitals</Link>
            </Menu.Item>
            <Menu.Item key="globalComplaints">
              <Link to="/add/complaints/globalList">Complaints</Link>
            </Menu.Item>
            <Menu.Item key="personalHistory">
              <Link to="/add/personalHistory/globalList">Personal History</Link>
            </Menu.Item>
            <Menu.Item key="medicalHistory">
              <Link to="/add/medicalHistory/globalList">Medical History</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="analytics">
            <Link to="/analytics">
              <Icon type="line-chart" />
              Analytics
            </Link>
          </Menu.Item>
          <Menu.Item key="system">
            <Link to="/system">
              <Icon type="fire" />
              System Status
            </Link>
          </Menu.Item>
          <Menu.Item key="clicked" onClick={() => localStorage.clear()}>
            <Link to="/auth">
              <Icon type="logout" />
              Logout
            </Link>
          </Menu.Item>
        </Menu>
      );
    }
  }
}
