import React, { Fragment } from "react";
import { requestOptions, API_ROOT } from "../util";
import { post, get } from "axios";
import axios from "axios";
import {
  Row,
  Col,
  Button,
  Input,
  Select,
  Icon,
  Card,
  List,
  Badge,
  Tag,
  Typography,
  Drawer
} from "antd";
import { openNotification } from "../util";
import EditMedicine from '../editMedicine'
const Option = Select.Option;

export default class RequestedMedicine extends React.Component {
  state = {
    loading: false,
    drawerVisibility: false,
    currentMedicine: [{
      medicine_name: ''
    }],
    medicines: [
      {
        id: 134060,
        mrp: 77.0,
        clinic_id: 1,
        clinic_name: "Pulmo Full clinic",
        medicine_name: "Ukhiuhuhku",
        generic_name: "AMBROXOL Hydrochloride 30mg",
        company_name: "4 Care Lifescience Pvt Ltd",
        type_name: "Nasal Spray"
      },
      {
        id: 134059,
        mrp: 56.0,
        clinic_id: 1,
        clinic_name: "Pulmo Full clinic",
        medicine_name: "Test678",
        generic_name: "Ispaghula 3.5 gm + Lactulose 10 gm",
        company_name: "3M INDIA",
        type_name: "Tablet"
      }
    ]
  };

  fetchMeds = (value = "") => {
    axios
      .get(`${process.env.REACT_APP_API_ROOT}/admin/requested_medicines`)
      .then(res => {
        this.setState({
          medicines: res.data.list,
          loading: false
        });
      })
      .catch(error => {
        // handle error
        this.setState({
          loading: false
        });
        console.log(error);
      });
  };
  postMedicines = instance => {
    this.setState({
      loading: true
    });
    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" }
    };
    formData.append("generic_name", instance.generic_name);
    formData.append("medicine_name", instance.medicine_name);
    formData.append("company_name", instance.company_name);
    formData.append("type_name", instance.type_name);
    formData.append("mrp", instance.mrp);
    formData.append("is_public", 1);

    axios
      .post(
        `${process.env.REACT_APP_API_ROOT}/admin/add_medicine`,
        formData,
        config
      )
      .then(res => {
        openNotification("success", "Messages Added Successfully");
        this.setState({
          loading: false
        });
      })
      .catch(error => {
        console.error(error);
        openNotification("error", `${error}`);
        this.setState({
          loading: false
        });
      });
  };

  showDrawer = (deet) => {
    this.setState({
      drawerVisibility: true,
      currentMedicine: [deet]
    });
  };

  onClose = () => {
    this.setState({
      drawerVisibility: false,
    });
  };

  componentDidMount() {
    if (!localStorage.token) {
      window.location.href = "/auth";
    }
    // this.fetchMeds();
  }

  render() {
    const {
      medicines,
      medicine_detail,
      template_detail,
      prescriptionCount
    } = this.state;
    return (
      <div>
        <React.Fragment>
          <Row gutter={16}>
            <Col span={12} offset={6}>
              {medicines.length > 0 && (
                <div>
                  <h3 style={{ marginTop: "1rem" }}>Requested Medicines</h3>
                  {medicines.map((deet, index) => {
                    return (
                      <Card
                        loading={this.state.loading}
                        className="viewMedicine"
                        style={{ marginBottom: "1rem" }}
                        title={deet.clinic_name}
                        extra={
                          <div>
                            <Button
                              type="default"
                              icon="edit"
                              loading={this.state.loading}
                              onClick={this.showDrawer.bind(this, deet)}
                              style={{
                                marginRight: '1rem'
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              icon="check"
                              loading={this.state.loading}
                              onClick={this.postMedicines.bind(this, deet)}
                              style={{
                                backgroundColor: '#52c41a',
                                borderColor: '#52c41a',
                                color: 'white'
                              }}
                            >
                              Approve
                            </Button>
                          </div>

                        }
                      >
                        <Typography.Paragraph copyable={{ text: `${deet.id}` }}>
                          ID: {deet.id}
                        </Typography.Paragraph>
                        <Typography.Paragraph
                          copyable={{ text: `${deet.medicine_name}` }}
                        >
                          Medicine: {deet.medicine_name}
                          <Badge
                            overflowCount={9999}
                            count={prescriptionCount}
                            showZero
                            style={{
                              marginLeft: "1rem"
                            }}
                          />
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          Company: {deet.company_name}
                        </Typography.Paragraph>

                        <Typography.Paragraph
                          copyable={{ text: `${deet.generic_name}` }}
                        >
                          Generic: {deet.generic_name}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          Type: <Tag color="#2db7f5">{deet.type_name}</Tag>
                        </Typography.Paragraph>
                      </Card>
                    );
                  })}
                </div>
              )}
            </Col>
          </Row>
          <Drawer
            title={`Update/Edit ${this.state.currentMedicine[0].medicine_name}`}
            width={720}
            onClose={this.onClose}
            visible={this.state.drawerVisibility}
            destroyOnClose
          >
            <EditMedicine medicine={this.state.currentMedicine} postHook={this.onClose} hideSearch/>
          </Drawer>
        </React.Fragment>
      </div>
    );
  }
}
