import axios from "axios";
import React, { Fragment, Component, Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Spin, Alert } from "antd";
import Header from "./header";
import { openNotification } from "./util";
import Sink from "./sink";
import RequestedMedicine from "./requestedMedicine";
import SystemWideReport from "./reports";
import { WrappedMetadata } from "./metadata/index";
// import Dashboard from "./dashboard";
// import Auth from "./auth";
// import AllClincs from "./allClinic";
// import EditClinic from "./editClinic";
// import UpdatePrescription from "./updatePrescription";
// import AllEmployees from "./allEmployees";
// import ViewClinic from "./viewClinic";
// import ViewSymptoms from "./symptoms/viewSymptoms";
// import PrescriptionTemplates from "./prescriptionTemplate";
// import MatchMedicines from "./matchMedicines";
// import AddMedicine from "./addMedicine";
// import ListResponsibilities from "./symptoms/ListResponsibilities";
// import GroupOne from "./symptoms/groupOne";
// import GroupTwo from "./symptoms/groupTwo";
// import Diagnosis from "./diagnosis";
// import CloneQuestions from "./clones/questions";
// import CloneDiagnosis from "./clones/diagnosis";
// import ClonePrescription from "./clones/prescription";
// import ViewMedicine from "./viewMedicine";
// import GlobalList from "./globalList";
// import ViewAnalytics from "./analytics";
// import ViewTodayAppoinments from "./analytics/viewTodayAppoinments";
// import AllClinicDetails from "./analytics/allClinicDetails";
// import monthlyStats from "./analytics/monthlyReport";
// import GetNextVisitInstructions from "./prescriptionTemplate/GetNextVisit";
// import AddNextVisitInstructions from "./prescriptionTemplate/AddNextVisit";

const Dashboard = lazy(() => import("./dashboard"));
const Auth = lazy(() => import("./auth"));
const AllClincs = lazy(() => import("./allClinic"));
const EditClinic = lazy(() => import("./editClinic"));
const UpdatePrescription = lazy(() => import("./updatePrescription"));
const AllEmployees = lazy(() => import("./allEmployees"));
const ViewClinic = lazy(() => import("./viewClinic"));
const ViewSymptoms = lazy(() => import("./symptoms/viewSymptoms"));
const PrescriptionTemplates = lazy(() => import("./prescriptionTemplate"));
const MatchMedicines = lazy(() => import("./matchMedicines"));
const AddMedicine = lazy(() => import("./addMedicine"));
const ListResponsibilities = lazy(() =>
  import("./symptoms/ListResponsibilities")
);
// const Header = lazy(() => import("./header"));
const GroupOne = lazy(() => import("./symptoms/groupOne"));
const GroupTwo = lazy(() => import("./symptoms/groupTwo"));
const Diagnosis = lazy(() => import("./diagnosis"));
const CloneQuestions = lazy(() => import("./clones/questions"));
const CloneDiagnosis = lazy(() => import("./clones/diagnosis"));
const ClonePrescription = lazy(() => import("./clones/prescription"));
const ViewMedicine = lazy(() => import("./viewMedicine"));
const EditMedicine = lazy(() => import("./editMedicine"));
const GlobalList = lazy(() => import("./globalList"));
const ViewAnalytics = lazy(() => import("./analytics"));
const ViewTodayAppoinments = lazy(() =>
  import("./analytics/viewTodayAppoinments")
);
const AllClinicDetails = lazy(() => import("./analytics/allClinicDetails"));
const monthlyStats = lazy(() => import("./analytics/monthlyReport"));
const GetNextVisitInstructions = lazy(() =>
  import("./prescriptionTemplate/GetNextVisit")
);
const AddNextVisitInstructions = lazy(() =>
  import("./prescriptionTemplate/AddNextVisit")
);
const ViewTemplateList = lazy(() =>
  import("./prescriptionTemplate/ViewTemplateList")
);
const ViewTemplate = lazy(() => import("./prescriptionTemplate/ViewTemplate"));
const AddGlobalVital = lazy(() => import("./globalList/addGlobalVitals"));
const AddGlobalComplaints = lazy(() =>
  import("./globalList/addGlobalComplaints")
);
const AddGlobalPersonalHistory = lazy(() =>
  import("./globalList/addGlobalPersonalHistory")
);
const AddGlobalMedicalHistory = lazy(() =>
  import("./globalList/addGlobalMedicalHistory")
);
const ReportTemplate = lazy(() => import("./globalList/reportTemplate"));
const ReorderTemplates = lazy(() =>
  import("./prescriptionTemplate/ReorderTemplate")
);
const SystemReport = lazy(() => import("./analytics/systemReport"));
// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.headers.token = localStorage.token;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    if (response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    if (response.status == false) {
      openNotification("error", "something went wrong");
    }
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default class Main extends Component {
  render() {
    return (
      <main>
        <BrowserRouter>
          <Header />
          <Switch>
            <Suspense fallback={<div>Loading...</div>}>
              {/* <React.Fragment> */}

              <Route exact path="/" component={AllClincs} />
              <Route exact path="/auth" component={Auth} />
              <Route exact path="/system" component={SystemReport} />
              <Route exact path="/addClinic" component={Dashboard} />
              <Route
                exact
                path="/editClinic/:clinicId"
                component={EditClinic}
              />
              <Route
                exact
                path="/updatePrescription/:clinicId"
                component={UpdatePrescription}
              />
              <Route
                path="/questions/add/:clinicId/:doctorId/:categoryId/1"
                component={GroupOne}
              />
              <Route
                path="/questions/add/:clinicId/:doctorId/:categoryId/2"
                component={GroupTwo}
              />
              <Route exact path="/employees" component={AllEmployees} />
              <Route exact path="/clinic/:clinicId" component={ViewClinic} />
              <Route
                path="/questions/view/:clinicId/:doctorId/:categoryId"
                component={ViewSymptoms}
              />
              <Route
                exact
                path="/prescription/template/:clinicId/:doctorId"
                component={PrescriptionTemplates}
              />
              <Route
                exact
                path="/prescription/template/list/view/:clinicId/:doctorId"
                component={ViewTemplateList}
              />
              <Route
                exact
                path="/prescription/template/view/:clinicId/:doctorId/:doctorDiagnosisId"
                component={ViewTemplate}
              />
              <Route
                exact
                path="/prescription/template/edit/:clinicId/:doctorId/:doctorDiagnosisId"
                component={ReorderTemplates}
              />
              <Route exact path="/match" component={MatchMedicines} />
              <Route exact path="/medicine/add" component={AddMedicine} />
              <Route
                exact
                path="/questions/list/:clinicId/:doctorId"
                component={ListResponsibilities}
              />
              <Route exact path="/medicine/view" component={ViewMedicine} />
              <Route exact path="/medicine/edit" component={EditMedicine} />
              <Route
                exact
                path="/diagnosis/:clinicId/:doctorId"
                component={Diagnosis}
              />
              <Route exact path="/clone/questions" component={CloneQuestions} />
              <Route exact path="/clone/diagnosis" component={CloneDiagnosis} />
              <Route
                exact
                path="/clone/prescriptions"
                component={ClonePrescription}
              />
              <Route
                exact
                path="/globalList/:clinicId/:doctorId"
                component={GlobalList}
              />
              <Route exact path="/analytics" component={ViewAnalytics} />
              <Route
                exact
                path="/analytics/today"
                component={ViewTodayAppoinments}
              />
              <Route
                exact
                path="/analytics/allclinic"
                component={AllClinicDetails}
              />
              <Route
                exact
                path="/analytics/monthlyreport/:clinicId"
                component={monthlyStats}
              />
              <Route
                exact
                path="/nextVisitInstructions/:clinicId"
                component={GetNextVisitInstructions}
              />
              <Route
                exact
                path="/nextVisitInstructions"
                component={AddNextVisitInstructions}
              />
              <Route
                exact
                path="/add/vital/globalList"
                component={AddGlobalVital}
              />
              <Route
                exact
                path="/add/complaints/globalList"
                component={AddGlobalComplaints}
              />
              <Route
                exact
                path="/add/personalHistory/globalList"
                component={AddGlobalPersonalHistory}
              />
              <Route
                exact
                path="/add/medicalHistory/globalList"
                component={AddGlobalMedicalHistory}
              />
              <Route
                exact
                path="/reportsTemplate/:clinicId/:doctorId"
                component={ReportTemplate}
              />
              <Route exact path="/sink/:clinicId/:doctorId" component={Sink} />
              <Route
                exact
                path="/medicine/requests"
                component={RequestedMedicine}
              />
              <Route exact path="/report/system" component={SystemWideReport} />
              <Route
                exact
                path="/metadata/:clinicId/:doctorId"
                component={WrappedMetadata}
              />
              {/* </React.Fragment> */}
            </Suspense>
          </Switch>
        </BrowserRouter>
      </main>
    );
  }
}
