import React, { Fragment } from "react";
import { requestOptions, API_ROOT } from "../util";
import { post, get } from "axios";
import axios from "axios";
import { Row, Col, Button, Input, Select, Icon, Card, List, Badge, Tag, Typography, AutoComplete } from "antd";
import { openNotification } from "../util";
import {debounce} from "lodash";
const Option = Select.Option;

export default class EditMedicine extends React.Component {
  state = {
    searchText: "",
    currentMedicine: "",
    medicines: [],
    medicine_detail: [],
    template_detail: [],
    prescriptionCount: null,
    medicineEdit: {
      generic_name: "",
      medicine_name: "",
      company_name: "",
      type_name: "",
      mrp: ""
    },
    generic: [],
    company: [],
    medicineType: []
  };

  fetchMeds = (value = "") => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_ROOT
        }/template_medicine_search?search=${value}`
      )
      .then(res => {
        this.setState({
          medicines: res.data.medicine_list,
          loading: false
        });
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  };
  fetchMedtoTemplateData = (value = "") => {
    axios
      .get(
        `${process.env.REACT_APP_API_ROOT}/admin/medicine_details?id=${value}`
      )
      .then(res => {
        this.setState({
          medicine_detail: res.data.medicine_detail,
          template_detail: res.data.template_detail,
          prescriptionCount: res.data.prescription,
          loading: false
        });
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  };
  fetchMedicineGeneric = value => {
    this.setState({
      loading: true
    });
    axios
      .get(`${process.env.REACT_APP_API_ROOT}/admin/generic?search=${value}`)
      .then(res => {
        this.setState({
          generic: res.data.list,
          loading: false
        });
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  };

  fetchMedicineCompany = value => {
    this.setState({
      loading: true
    });
    axios
      .get(`${process.env.REACT_APP_API_ROOT}/admin/company?search=${value}`)
      .then(res => {
        this.setState({
          company: res.data.list,
          loading: false
        });
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  };
  fetchMedicineType = value => {
    this.setState({
      loading: true
    });
    axios
      .get(
        `${process.env.REACT_APP_API_ROOT}/admin/medicine_type?search=${value}`
      )
      .then(res => {
        this.setState({
          medicineType: res.data.list,
          loading: false
        });
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  };
  postMedicines = instance => {
    this.setState({
      loading: true
    });
    let formData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" }
    };
    formData.append("generic_name", instance.generic_name);
    formData.append("medicine_name", instance.medicine_name);
    formData.append("company_name", instance.company_name);
    formData.append("type_name", instance.type_name);
    formData.append("id", instance.id);
    if (this.props.medicine) {
      formData.append("is_public", 1);
    }

    axios
      .post(
        `${process.env.REACT_APP_API_ROOT}/admin/add_medicine`,
        formData,
        config
      )
      .then(res => {
        if (this.props.medicine) {
          openNotification("success", "Medicine Added Successfully");
          this.setState({
            loading: false,
            medicine_detail: []
          }, () => this.props.postHook());

        }
        else {
          openNotification("success", "Medicine Added Successfully");
          this.setState({
            loading: false
          });
        }
      })
      .catch(error => {
        console.error(error);
        openNotification("error", `${error}`);
        this.setState({
          loading: false
        });
      });
  };
  handleSearch = value => {
    this.setState({ searchText: value });
    this.fetchMeds(value);
  };
  handleMedicineChange = value => {
    this.setState(
      { currentMedicine: value },
      this.fetchMedtoTemplateData(value)
    );
  };
  handleMedicineNameChange = (index, e) => {
    let copyState = this.state.medicine_detail;
    copyState[index]["medicine_name"] = e.target.value;
    this.setState({ medicine_detail: copyState });
  };

  handleMRPChange = (index, e) => {
    let copyState = this.state.medicines;
    copyState[index]["mrp"] = e.target.value;
    this.setState({ medicines: copyState });
  };
  handleGenericSearch = value => {
    if (value.length > 2) {
      this.fetchMedicineGeneric(value);
    }
  };
  handleTypeSearch = value => {
    if (value.length > 2) {
      this.fetchMedicineType(value);
    }
  };
  handleCompanyChange = value => {
    if (value.length > 2) {
      this.fetchMedicineCompany(value);
    }
  };

  handleCompanyValueChange = (index, value) => {
    let copyState = { ...this.state };
    copyState.medicine_detail[index].company_name = value;
    this.setState(copyState);
    this.handleCompanyChange(value);
  };
  handleGenericValueChange = (index, value) => {
    let copyState = { ...this.state };
    copyState.medicine_detail[index].generic_name = value;
    this.setState(copyState);
    this.handleGenericSearch(value);
  };
  handleTypeValueChange = (index, value) => {
    let copyState = { ...this.state };
    copyState.medicine_detail[index].type_name = value;
    this.setState(copyState);
    this.handleTypeSearch(value);
  };
  handleSubmit = () => {
    console.log(this.state.medicines);
    this.state.medicine_detail.map((instance, index) => {
      this.postMedicines(instance);
    });
  };
  componentDidMount() {
    if (!localStorage.token) {
      window.location.href = "/auth";
    }
    if (this.props.medicine) {
      this.setState({
        medicine_detail: this.props.medicine
      })
    }
    this.fetchMeds();
  }

  render() {
    const { medicines, medicine_detail, template_detail, prescriptionCount } = this.state;
    const genericName = this.state.generic.map(gen => (
      <Option key={gen.id} value={gen.name}>
        {gen.name}
      </Option>
    ));
    const companyName = this.state.company.map(comp => (
      <Option key={comp.id} value={comp.name}>
        {comp.name}
      </Option>
    ));
    const typeName = this.state.medicineType.map(comp => (
      <Option key={comp.id} value={comp.name}>
        {comp.name}
      </Option>
    ));
    return (
      <div>
        <React.Fragment>
          <Row gutter={16}>
          {this.props.showSearch ? (
            <Col span={12} offset={6}>
              <Select
                allowClear
                showSearch
                name="type"
                placeholder="Search a medicine to get started"
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={false}
                onSearch={this.handleSearch}
                onChange={this.handleMedicineChange}
                style={{
                  width: "100%",
                  marginTop: '2rem'
                }}
              >
                {medicines.map(type => (
                  <Option value={type.id}>{type.name}</Option>
                ))}
              </Select>
            </Col>
          ) : null}

            <Col span={this.props.medicine ? 24 : 12} offset={this.props.medicine ? 0 : 6}>
              {this.state.medicine_detail.map((item, index) => {
                return (
                  <React.Fragment>
                    <Card
                      title={this.props.medicine ? null:`Edit Medicine`}
                      style={{ marginTop: "1rem" }}
                    >
                      <label>generic_name</label>
                      <br />
                      <AutoComplete
                        defaultValue={item.generic_name}
                        dataSource={this.state.generic}
                        style={{ width: "100%" }}
                        onSelect={this.handleGenericValueChange.bind(this, index)}
                        onSearch={debounce(
                          this.handleGenericValueChange.bind(this, index)
                        )}
                      >
                        {genericName}
                      </AutoComplete>
                      <br />
                      <label>medicine_name</label>
                      <br />
                      <Input
                        defaultValue={item.medicine_name}
                        onChange={this.handleMedicineNameChange.bind(this, index)}
                      />
                      <br />
                      <label>company_name</label>
                      <br />

                      <AutoComplete
                        defaultValue={item.company_name}
                        dataSource={this.state.company}
                        style={{ width: "100%" }}
                        onSelect={this.handleCompanyValueChange.bind(this, index)}
                        onSearch={debounce(
                          this.handleCompanyValueChange.bind(this, index)
                        )}
                      >
                        {companyName}
                      </AutoComplete>
                      <br />
                      <label>type_name</label>
                      <br />
                      <AutoComplete
                        defaultValue={item.type_name}
                        dataSource={this.state.medicineType}
                        style={{ width: "100%" }}
                        onSelect={this.handleTypeValueChange.bind(this, index)}
                        onSearch={debounce(
                          this.handleTypeValueChange.bind(this, index)
                        )}
                      >
                        {typeName}
                      </AutoComplete>
                    </Card>
                  </React.Fragment>
                )
              })}
              <React.Fragment>
                {medicine_detail.length > 0 && (
                  <Button
                    loading={this.state.loading}
                    type="primary"
                    icon="upload"
                    onClick={this.handleSubmit}
                    style={{marginTop: '1rem'}}
                  >
                   {this.props.medicine ? "Save & Approve" : "Save"}
                  </Button>
                )}
              </React.Fragment>
            </Col>
          </Row>
        </React.Fragment>
      </div>
    );
  }
}
