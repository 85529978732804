import React from "react";
import { Upload, Icon, Modal, message, Button } from "antd";
import axios from "axios";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default class PicturesWall extends React.Component {
  state = {
    uploading: false,
    previewVisible: false,
    previewImage: "",
    fileList: [],
    image_ids: []
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });
  handleUpload = async options => {
    const { fileList } = this.state;
    // console.log(options);

    const formData = new FormData();

    this.setState({
      uploading: true
    });
    const config = {
      headers: { "content-type": "multipart/form-data" }
    };
    fileList.forEach(async file => {
      formData.append("image", JSON.stringify(file));
      const uploads = await axios.post(
        `${process.env.REACT_APP_API_ROOT}/add_image`,
        formData,
        config
      );
      console.log("uploads", uploads);
      this.setState(state => ({
        image_ids: [...state.image_ids, uploads.data.image_id]
      }));
    });
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const uploadProps = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file]
        }));
        return false;
      }
    };
    return (
      <div className="clearfix">
        <Upload
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          customRequest={options => this.handleUpload(options)}
          {...uploadProps}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Button type="default" onClick={this.handleUpload}>
          Upload
        </Button>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}
