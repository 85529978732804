import moment from "moment";
import {notification} from 'antd'
export const requestOptions = {
  method: "POST",
  headers: {
    "Content-type": "application/json; charset=UTF-8",
    Authorization: "Bearer 7f67f09985436a9393dead69a7c00ee80931d7d1a27dzzee"
    //change this line ^ according to your requirements
  }
};

export const base_url = "http://jsonplaceholder.typicode.com/posts";

export const API_ROOT = `${process.env.REACT_APP_API_ROOT}`;

export const timeStringToMomentDate = timeString => {
  const dt = moment();
  const timeStringArray = timeString.split(":");
  dt.hour(timeStringArray[0]);
  dt.minute(timeStringArray[1]);
  return dt;
};


export const convertUnicode = (input) => {
  return input.replace(/\\u(\w\w\w\w)/g,function(a,b) {
    var charcode = parseInt(b,16);
    return String.fromCharCode(charcode);
  });
}

export const openNotification = (type, message) => {
  if (type === "success") {
    notification["success"]({
      message: `Success`,
      description: `${message}`
    });
  } else {
    notification["error"]({
      message: "Something went wrong",
      description: `${message}`
    });
  }
};

export const getToken = () => {
    if (localStorage.token) {
        return localStorage.token;
    }
    return false;
};
