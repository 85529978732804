import React, { Component } from "react";
import { Row, Col, Input, Button, Form, TimePicker, Icon } from "antd";
import axios from "axios";
import moment from "moment";
import PicturesWall from "./uploadGallery";
class ClinicData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinic: {
        name: "",
        doctor_id: "",
        address_id: "",
        address: {},
        doctor: {
          specialist: "",
          description: ""
        },
        category: [],
        diagnosis: [],
        hours: [],
        speciality: ""
      },
      clinicWorkingHrs: [
        {
          from: "00:00",
          to: "00:00"
        }
      ]
    };
  }

  fetchClinicDetails = () => {
    this.setState({
      loading: true
    });
    axios
      .get(
        `${process.env.REACT_APP_API_ROOT}/clinics/${this.props.match.params.clinicId}`
      )
      .then(res => {
        this.setState({
          clinic: res.data,
          clinicWorkingHrs: res.data.hours,
          loading: false
        });
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  };
  handleChange = event => {
    console.log(event.target.value);
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
    });
  };
  handleAddWorkingHrs = () => {
    const stateCopy = { ...this.state };
    const newTimings = {
      from: "00:00",
      to: "00:00"
    };
    stateCopy.clinicWorkingHrs.push(newTimings);
    this.setState(stateCopy);
  };

  handleRemoveWorkingHrs = (index, e) => {
    let stateCopy = this.state.clinicWorkingHrs;
    // console.log(index, stateCopy.clinicWorkingHrs);
    stateCopy.splice(index, 1);
    this.setState({ clinicWorkingHrs: stateCopy });
  };
  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    console.log("keys", keys);
    // We need at least one passenger
    // if (keys.length === 1) {
    //   return;
    // }

    // can use data-binding to set
    // form.setFieldsValue({
    //   keys: keys.filter(key => key !== k)
    // });
  };
  updateShiftDeets = (index, key, moment, time) => {
    console.log(index, key, moment, time);
    let stateCopy = { ...this.state };
    let clinicWorkingHrs = stateCopy.clinicWorkingHrs[index];
    clinicWorkingHrs[key] = time;
    this.setState(stateCopy);
  };
  componentDidMount() {
    this.fetchClinicDetails();
  }

  render() {
    // const { getFieldDecorator } = this.props.form;
    const { name, address } = this.state.clinic;
    const { clinicWorkingHrs } = this.state;
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Clinic Name">
            {this.props.form.getFieldDecorator("clinicName", {
              initialValue: name
            })(<Input />)}
          </Form.Item>
          <Form.Item label="About the clinic">
            {this.props.form.getFieldDecorator("clinicDescription", {
              initialValue: ""
            })(<Input.TextArea rows={4} />)}
          </Form.Item>
          <Form.Item label="Address">
            {this.props.form.getFieldDecorator("clinicAddress", {
              initialValue: address.address_line
            })(<Input.TextArea rows={4} />)}
          </Form.Item>
          <Form.Item label="City">
            {this.props.form.getFieldDecorator("clinicCity", {
              initialValue: address.city
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Zipcode">
            {this.props.form.getFieldDecorator("clinicZip", {
              initialValue: address.zip
            })(<Input />)}
          </Form.Item>
          <Form.Item label="State">
            {this.props.form.getFieldDecorator("clinicState", {
              initialValue: address.state
            })(<Input />)}
          </Form.Item>
          <Row>
            <Col span="10">
              <Form.Item label="Latitude">
                {this.props.form.getFieldDecorator("clinicLat", {
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span="10" offset="1">
              <Form.Item label="Longitude">
                {this.props.form.getFieldDecorator("clinicLong", {
                  initialValue: ""
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Phone">
            {this.props.form.getFieldDecorator("clinicContact", {
              initialValue: ""
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Working Hours">
            {clinicWorkingHrs.map((hrs, index) => {
              return (
                <Row>
                  <TimePicker
                    allowClear={false}
                    format="HH:mm"
                    minuteStep={15}
                    value={moment(clinicWorkingHrs[index].from, "HH:mm")}
                    onChange={this.updateShiftDeets.bind(this, index, "from")}
                  />
                  <TimePicker
                    allowClear={false}
                    format="HH:mm"
                    minuteStep={15}
                    value={moment(clinicWorkingHrs[index].to, "HH:mm")}
                    onChange={this.updateShiftDeets.bind(this, index, "to")}
                  />
                  {clinicWorkingHrs.length > 1 && (
                    <Button
                      type="primary"
                      onClick={this.handleRemoveWorkingHrs.bind(this, index)}
                    >
                      <Icon type="minus" />
                    </Button>
                  )}
                  {clinicWorkingHrs.length === index + 1 && (
                    <Button
                      type="primary"
                      onClick={this.handleAddWorkingHrs.bind(this, index)}
                    >
                      <Icon type="plus" />
                    </Button>
                  )}
                </Row>
              );
            })}
          </Form.Item>
        </Form>
      </div>
    );
  }
}
export const WrappedClinicData = Form.create({ name: "Metadata" })(ClinicData);
