import React, { Component } from "react";
import { Row, Col, Input, Button, Form, TimePicker, Icon, Card } from "antd";
import axios from "axios";
import moment from "moment";
import { WrappedClinicData } from "./clinicData";
import DoctorData from "./doctorData";
import PicturesWall from "./uploadGallery";
class Metadata extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.clinicRef = React.createRef();
    this.doctorRef = React.createRef();
    this.pictureRef = React.createRef();
  }
  submit = () => {
    let obj = {
      clinic_id: this.props.match.params.clinicId,
      image_id: "2345",
      gallery: JSON.stringify(this.pictureRef.current.state.image_ids),
      meta: {
        clinicData: this.clinicRef.current.props.form.getFieldsValue(),
        doctorData: this.doctorRef.current.state.doctorProfiles
      }
    };
    console.log(
      "clinicRef",
      this.clinicRef.current.props.form.getFieldsValue()
    );
    console.log("doctorRef", this.doctorRef.current);
    console.log("pictureRef", this.pictureRef.current);
    console.log("obj", JSON.stringify(obj));
    axios.post(`${process.env.REACT_APP_API_ROOT}/post_clinic_meta`, obj).then(res => console.log(res.data))
  };

  render() {
    return (
      <div>
        <Row gutter={16} style={{ marginTop: "1rem" }}>
          <Col span={10} offset={1}>
            <Card title="Clinic Profile">
              <WrappedClinicData {...this.props} ref={this.clinicRef} />
              <label>Clinic Gallery</label>
              <PicturesWall {...this.props} ref={this.pictureRef} />
            </Card>
          </Col>
          <Col span={10} offset={1}>
            <DoctorData {...this.props} ref={this.doctorRef} />
          </Col>
        </Row>
        <Row gutter={16} justify="center" type="flex" style={{marginTop: '1rem'}}>
            <Col span={8}>
                <Button type="primary" onClick={this.submit} block>Submit all</Button>
            </Col>
        </Row>
      </div>
    );
  }
}
export const WrappedMetadata = Form.create({ name: "Metadata" })(Metadata);
