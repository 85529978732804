import React, { Component } from "react";
import {
  List,
  Button,
  Drawer,
  Form,
  Input,
  Row,
  Col,
  Select,
  Avatar,
  Card
} from "antd";
import authority from "../registerClinic/authority.json";
import UploadAvatar from "./uploadAvatar";
export default class DoctorData extends Component {
  state = {
    drawerVisiblity: false,
    editMode: false,
    currentItem: null,
    doctorProfiles: [
      {
        doctorRegAuthority: "Arunachal Pradesh Medical Council",
        doctorName: "asd",
        doctorDescription: "asd",
        doctorRegNumber: "asd",
        doctorQualification: "asd",
        doctorAccolades: "asd",
        image_id: null
      }
    ],
    doctorRegAuthority: null,
    doctorName: null,
    doctorDescription: null,
    doctorRegNumber: null,
    doctorQualification: null,
    doctorAccolades: null,
    image_id: null
  };
  toggleDrawer = () => {
    this.setState(state => ({
      drawerVisiblity: !state.drawerVisiblity
    }));
  };
  onClose = () => {
    this.setState({
      doctorRegAuthority: null,
      doctorName: null,
      doctorDescription: null,
      doctorRegNumber: null,
      doctorQualification: null,
      doctorAccolades: null,
      editMode: false,
      drawerVisiblity: false
    });
  };

  handleChange = e => {
    const key = e.target.name;
    const value = e.target.value;
    this.setState(state => ({
      [key]: value
    }));
  };
  updateRegAuthority = e => {
    this.setState(state => ({
      doctorRegAuthority: e
    }));
  };
  onSubmit = e => {
    const {
      doctorRegAuthority,
      doctorName,
      doctorDescription,
      doctorRegNumber,
      doctorQualification,
      doctorAccolades,
      image_id
    } = this.state;
    let obj = {
      doctorRegAuthority,
      doctorName,
      doctorDescription,
      doctorRegNumber,
      doctorQualification,
      doctorAccolades,
      image_id
    };
    this.setState(state => ({
      doctorProfiles: [...state.doctorProfiles, obj]
    }));
  };
  editItem = index => {
    const {
      doctorRegAuthority,
      doctorName,
      doctorDescription,
      doctorRegNumber,
      doctorQualification,
      doctorAccolades,
      image_id
    } = this.state.doctorProfiles[index];
    let obj = {
      doctorRegAuthority,
      doctorName,
      doctorDescription,
      doctorRegNumber,
      doctorQualification,
      doctorAccolades,
      image_id,
      drawerVisiblity: true,
      editMode: true,
      currentItem: index
    };
    this.setState(obj);
  };
  update = () => {
    const {
      doctorRegAuthority,
      doctorName,
      doctorDescription,
      doctorRegNumber,
      doctorQualification,
      doctorAccolades,
      currentItem,
      image_id
    } = this.state;
    const doctorProfiles = [...this.state.doctorProfiles];
    let obj = {
      doctorRegAuthority,
      doctorName,
      doctorDescription,
      doctorRegNumber,
      doctorQualification,
      doctorAccolades,
      image_id
    };
    console.log("doctorProfiles", doctorProfiles);
    doctorProfiles.splice(currentItem, 1, obj);
    this.setState({
      doctorProfiles,
      editMode: false,
      drawerVisiblity: false,
      doctorRegAuthority: null,
      doctorName: null,
      doctorDescription: null,
      doctorRegNumber: null,
      doctorQualification: null,
      doctorAccolades: null,
      image_id: null
    });
  };
  updateAvatarId = avatarId => {
    this.setState({ image_id: avatarId });
  };

  render() {
    const { doctorProfiles } = this.state;
    const {
      doctorRegAuthority,
      doctorName,
      doctorDescription,
      doctorRegNumber,
      doctorQualification,
      doctorAccolades,
      editMode
    } = this.state;
    if (!doctorProfiles.length) {
      return (
        <>
          <Button type="primary" onClick={this.toggleDrawer}>
            Add New
          </Button>
        </>
      );
    }
    return (
      <div>
        <Card
          title="Doctor Profiles"
          extra={
            <>
              <Button type="default" onClick={this.toggleDrawer}>
                Add New
              </Button>
            </>
          }
        >
          <List
            dataSource={this.state.doctorProfiles}
            renderItem={(item, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  avatar={
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  }
                  title={<a href="https://ant.design">{item.doctorName}</a>}
                  description={item.doctorQualification}
                />
                <div>
                  <Button onClick={this.editItem.bind(this, index)}>
                    View/Edit
                  </Button>
                </div>
              </List.Item>
            )}
          />
        </Card>
        <Drawer
          width="40%"
          title={editMode ? `Edit ${doctorName}` : "Add New Doctor Profile"}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.drawerVisiblity}
          destroyOnClose
        >
          <Row>
            <label>Avatar</label>
            <UploadAvatar
              {...this.props}
              updateAvatarId={this.updateAvatarId}
            />
          </Row>
          <Row>
            <label>Name</label>
            <Input
              name="doctorName"
              onChange={this.handleChange}
              defaultValue={doctorName}
            />
          </Row>
          <Row>
            <label>Description</label>
            <Input
              name="doctorDescription"
              onChange={this.handleChange}
              defaultValue={doctorDescription}
            />
          </Row>
          <Row>
            <Col>
              <label>Reg.auth</label> <br />
              <Select
                value={doctorRegAuthority}
                onChange={this.updateRegAuthority}
                style={{ width: "100%" }}
              >
                {authority.map((i, index) => {
                  return (
                    <Select.Option key={index} value={i.authority}>
                      {i.authority}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
            <Col>
              <label>Registration Number</label>
              <Input
                name="doctorRegNumber"
                onChange={this.handleChange}
                defaultValue={doctorRegNumber}
              />
            </Col>
          </Row>
          <Row>
            <label>Qualification</label>
            <Input
              name="doctorQualification"
              onChange={this.handleChange}
              defaultValue={doctorQualification}
            />
          </Row>
          <Row>
            <label>Accolades</label>
            <Input.TextArea
              rows={4}
              name="doctorAccolades"
              onChange={this.handleChange}
              defaultValue={doctorAccolades}
            />
          </Row>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right"
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            {editMode ? (
              <Button onClick={this.update} type="primary">
                Update
              </Button>
            ) : (
              <Button onClick={this.onSubmit} type="primary">
                Submit
              </Button>
            )}
          </div>
        </Drawer>
      </div>
    );
  }
}
