import React, { Component } from "react";
import axios from "axios";
import { openNotification } from "../util";
import { orderBy } from "lodash";
import {
  Row,
  Col,
  Button,
  Input,
  Table,
  Tag,
  Typography,
  Icon,
  Spin
} from "antd";
import moment from "moment";
export default class SystemWideReport extends Component {
  state = {
    loading: true,
    clinics: [],
    currentClinic: 0,
    numberofClinics: 0
  };
  fetchAllClinics = () => {
    axios
      .get(`${process.env.REACT_APP_API_ROOT}/clinics`)
      .then(res => {
        let addKeys = res.data.map(item => {
          return {
            ...item,
            employeeData: []
          };
        });
        let sortedData = orderBy(addKeys, ["id"], ["desc"]);
        console.log("sortedData", sortedData);
        this.setState(
          {
            clinics: sortedData,
            numberofClinics: sortedData.length
          },
          () => this.promiseToFetchEmp()
        );
      })
      .catch(error => {
        this.setState({
          loading: false
        });
        openNotification("error", `${error}`);
      });
  };
  promiseToFetchEmp = () => {
    console.log("#clinics", this.state.clinics);
    this.state.clinics.map(clinic => {
      this.fetchEmployeeList(clinic.id);
    });
  };
  fetchEmployeeList = clinicId => {
    axios
      .get(`${process.env.REACT_APP_API_ROOT}/user_list/${clinicId}`)
      .then(res => {
        let copyState = { ...this.state };
        let findClinic = copyState.clinics.find(x => x.id == clinicId);
        findClinic.employeeData = res.data;
        copyState.currentClinic = this.state.currentClinic + 1;
        this.setState(copyState);
        if (this.state.currentClinic == this.state.numberofClinics) {
          this.setState({
            loading: false
          });
        }
      })
      .catch(error => {
        // handle error
        console.log(error);
        this.setState({
          loading: false
        });
      });
  };
  print = () => {
    window.print();
  };

  componentDidMount() {
    this.fetchAllClinics();
  }
  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        width: 150,
        render: name => (
          <span
            style={{
              fontWeight: 600
            }}
          >
            {name}
          </span>
        )
      },
      {
        title: "Role",
        dataIndex: "role",
        width: 50,
        render: role => <span>{role.name}</span>
      },
      {
        title: "Username",
        dataIndex: "email",
        width: 100
      },
      {
        title: "Responsibilities",
        dataIndex: "responsibilities",
        width: 400,
        render: responsibilities =>
          responsibilities.map((resp, index) => {
            return (
              <Tag color="#2db7f5" key={index} className="systemWideReportResp">
                {resp.name}
              </Tag>
            );
          })
      }
    ];
    if (this.state.loading) {
      return (
        <div className="example">
          <Spin
            tip="Fetching Information..."
            style={{
              display: "block",
              margin: "10rem auto"
            }}
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Row
            gutter={16}
            style={{
              marginTop: "1rem"
            }}
          >
            <Col span={16} offset={1}>
              <Typography.Title
                level={2}
                style={{
                  textTransform: "uppercase"
                }}
              >
                System-wide report
              </Typography.Title>
              Generated on {`${moment(new Date()).format("dddd, MMMM Do YYYY")}`}
            </Col>
            <Col
              span={6}
              style={{
                textAlign: "right"
              }}
            >
              <Button
                className="printBtn"
                onClick={this.print}
                icon="printer"
                loading={this.state.loading}
              >
                Print
              </Button>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={22} offset={1}>
              <React.Fragment>
                {this.state.clinics.map((clinic, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Table
                        bordered
                        size="middle"
                        pagination={false}
                        rowKey={`${index}`}
                        columns={columns}
                        dataSource={clinic.employeeData}
                        title={() => (
                          <Typography.Text mark> {clinic.name}</Typography.Text>
                        )}
                        style={{
                          marginTop: "2rem"
                        }}
                      />
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}
